import { useEffect, useState } from "Shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useRef } from "haunted";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { deleteLoginInfo, revealDelayedElements, toBoolean } from "Services/common";
import { ref } from "Components/directives/ref";
import { AdminModalType } from "../admin-modal/admin-modal";
import { tealiumLog } from "Services/TealiumHelpers";
import DomCrawlingHelper from "Services/DomCrawlingHelper";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import numberFormatter from "Services/numberFormatter";
import { PcraLoginInfoModalType } from "Components/pcra-login-info-modal/peru-compra-login-info-modal";
import { useCultureSelector } from "./useCultureSelector";
import classNames from "classnames";
import { useLoginWidget } from "./useLoginWidget";
import { useAmericanUserData } from "./useAmericanUserData";
import { commonDebug } from "../../bootstrap";
import { LoginInfo } from "Shared/models/LoginInfo";
import { useReduxState } from "Shared/redux/useReduxState";
import { ApiUserInfo, MappedUserInfo } from "Shared/models/UserInfo";
import { mapUserInfo } from "ComponentMappers/UserInfoMapper";

export const useShadowDOM = false;
export const name = "ac-main-menu";

export const observedAttributes: (keyof Attributes)[] = [
	"absolute-action",
	"booking-url",
	"culture",
	"is-banco-estado-on",
	"is-peru-compra-on",
	"show-admin-menu",
	"show-menu",
	"wait-for-ibe-in-seconds",
];

export interface Attributes {
	"absolute-action": string;
	"booking-url": string;
	"culture": string;
	"is-banco-estado-on": string;
	"is-peru-compra-on": string;
	"show-admin-menu": string;
	"show-menu": string;
	"wait-for-ibe-in-seconds": string;
}

export interface Props {
	absoluteAction: string;
	bookingUrl: string;
	culture: string;
	isBancoEstadoOn: boolean;
	isPeruCompraOn: boolean;
	showAdminMenu: boolean;
	showMenu: boolean;
	waitForIbeInSeconds: number;
}

let didScroll = false;
let navbarHeight = 0;
let lastScrollTop = 0;

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		absoluteAction: host.absoluteAction,
		bookingUrl: host.bookingUrl,
		culture: host.culture,
		isBancoEstadoOn: toBoolean(host.isBancoEstadoOn),
		isPeruCompraOn: toBoolean(host.isPeruCompraOn),
		showAdminMenu: toBoolean(host.showAdminMenu),
		showMenu: toBoolean(host.showMenu),
		waitForIbeInSeconds: Number(host.waitForIbeInSeconds) || 30,
	};

	const root = useRef<HTMLDivElement>(undefined);
	const navBar = useRef<HTMLDivElement>(undefined);
	const mobileMenu = useRef<HTMLInputElement>(undefined);

	const [userInfo, setUserInfo] = useReduxState("userInfo");

	const [isBancoEstadoBarOpened, setIsBancoEstadoBarOpened] = useState<boolean>(false);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [userName, setUserName] = useState<string>("");
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [isSemiCollapsed, setIsSemiCollapsed] = useState<boolean>(false);
	const [isBancoEstadoTabOpen, setIsBancoEstadoTabOpen] = useState<boolean>(false);
	const [isLoginWidgetOpen, setIsLoginWidgetOpen] = useState(false);
	const [isAmericanDropdownOpen, setIsAmericanDropdownOpen] = useState(false);

	const loginWidget = useLoginWidget({
		bookingUrl: props.bookingUrl,
		absoluteAction: props.absoluteAction,
		isOpen: isLoginWidgetOpen,
		culture: props.culture,
		setIsOpen: setIsLoginWidgetOpen,
	});

	const languageAndCountry = (culture = props.culture) => {
		if (!culture) return { language: "es", country: "cl" };

		const [language, country] = culture.split("-");

		return { language: language || "es", country: country || "cl" };
	};

	const cultureSelector = useCultureSelector({
		culture: props.culture,
		languageAndCountry,
		onOpen: () => {
			setIsLoginWidgetOpen(false);
			setIsAmericanDropdownOpen(false);
			mobileMenu.current.checked = false;
			setIsBancoEstadoTabOpen(false);
		},
	});

	// HELPERS

	const profileUrl = () => `${props.bookingUrl}/V2/Profile?culture=${props.culture}&url=${props.absoluteAction}`;

	const bancoEstadoLoginUrl = () =>
		`${props.bookingUrl}/V2/Login?bancoe=1&culture=${props.culture}&url=${props.absoluteAction}`;

	const getUserInfo = async () => {
		const abortController = new AbortController();
		const baseUrl = window.JetSmart.Settings.BookingUrl + "/api/UserData/UserInfo";
		const fetchOptions: RequestInit = {
			method: "GET",
			credentials: "include",
			mode: "cors",
			signal: abortController.signal,
		};

		const fetchParameters = {
			options: fetchOptions,
			url: baseUrl,
		};

		const timer = window.setTimeout(() => {
			abortController.abort();
			setUserInfo({ RoleCode: "WWWA" });
			document.body.style.opacity = "1";
		}, props.waitForIbeInSeconds * 1000);

		try {
			const result = await fetch(fetchParameters.url, fetchParameters.options);
			window.clearTimeout(timer);

			if (result.ok) {
				const data = (await result.json()) as ApiUserInfo;
				return mapUserInfo(data);
			}

			return { RoleCode: "WWWA" };
		} catch (e) {
			commonDebug.error(e);
			window.clearTimeout(timer);
			return { RoleCode: "WWWA" };
		}
	};

	const getLoginInfo = async () => {
		const abortController = new AbortController();
		const baseUrl = window.JetSmart.Settings.BookingUrl + "/api/UserData/LoginInfo";
		const fetchOptions: RequestInit = {
			method: "GET",
			credentials: "include",
			mode: "cors",
			signal: abortController.signal,
		};

		const fetchParameters = {
			options: fetchOptions,
			url: baseUrl,
		};

		const timer = window.setTimeout(() => {
			abortController.abort();
			document.body.style.opacity = "1";
		}, props.waitForIbeInSeconds * 1000);

		try {
			const result = await fetch(fetchParameters.url, fetchParameters.options);
			window.clearTimeout(timer);

			if (result.ok) {
				const data = (await result.json()) as LoginInfo;
				return data;
			}

			return undefined;
		} catch (e) {
			window.clearTimeout(timer);
			commonDebug.error(e);
			return undefined;
		}
	};

	const handleLoginInfoResult = async (loginInfo: LoginInfo) => {
		if (loginInfo?.Tealium) {
			try {
				tealiumLog({
					eventName: loginInfo.Tealium.Action.toLowerCase() === "login" ? "user_login" : "user_register",
					eventParams: {
						ibe_release: loginInfo.Tealium.IbeRelease,
						organization: loginInfo.Tealium.OrganizationName,
						user_be_level: loginInfo.Tealium.UserBeLevel,
						user_dc_level: loginInfo.Tealium.UserDcLevel,
						user_programs: loginInfo.Tealium.UserPrograms.join("|"),
						user_role: loginInfo.Tealium.UserRole,
					},
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error("Unable to parse tealium info cookie.");
			}
		}

		if (loginInfo?.ChileCompra) {
			mobileMenu.current.checked = false;
			PUB_SUBS.ChileCompraLoginInfoModalOpened.publish({});
			return;
		}

		if (loginInfo?.PeruCompra) {
			mobileMenu.current.checked = false;
			PUB_SUBS.PcraLoginInfoModalOpened.publish({
				modalType: getUserTypeForLoginInfoModal(loginInfo?.Tealium?.UserRole),
			});
			return;
		}

		await deleteLoginInfo();
	};

	const handleUserInfoResult = (userInfoResult: MappedUserInfo) => {
		if (userInfoResult?.BancoEstado?.Category > 0) {
			validateRestrictedUrl();
			initBancoEstadoBar(userInfoResult.BancoEstado.Category);
		}

		if (userInfoResult?.Name) {
			setIsLoggedIn(true);
			setUserName(`${userInfoResult?.Name.FirstName} ${userInfoResult?.Name.LastName}`);
		}

		setUserInfo(userInfoResult);
		setAmplitudeDeviceId(userInfoResult.DeviceId);
	};

	const init = async () => {
		document.body.style.opacity = "0";

		const userInfoResult = await getUserInfo();
		handleUserInfoResult(userInfoResult);

		const loginInfoResult = await getLoginInfo();
		await handleLoginInfoResult(loginInfoResult);

		document.body.style.opacity = "1";
		revealDelayedElements(root.current);

		document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
			anchor.addEventListener("click", function (e) {
				e.preventDefault();
				window.setTimeout(() => setIsCollapsed(true), 1000);
				document.querySelector(anchor.getAttribute("href")).scrollIntoView({
					behavior: "smooth",
				});
			});
		});

		// eslint-disable-next-line no-console
		console.table(window.JetSmart.Variants);

		// FOR TESTING ONLY! REMOVE BEFORE GOING TO PROD!
		// setIsLoggedIn(true);
		// setUserName("Juan Carlo Pablo Esteban Montoya");
		// EOF FOR TESTING ONLY

		// https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
		window.addEventListener(
			"scroll",
			() => {
				didScroll = true;
				setIsLoginWidgetOpen(false);
				setIsAmericanDropdownOpen(false);
			},
			false
		);

		window.setInterval(() => {
			if (didScroll) {
				handleScroll();
				didScroll = false;
			}
		}, 125);

		navbarHeight = navBar?.current?.getBoundingClientRect().height || 0;
	};

	const setAmplitudeDeviceId = (deviceId: string) => {
		try {
			if (deviceId) {
				if (window.amplitude && typeof window.amplitude.setDeviceId === "function") {
					window.amplitude.setDeviceId(deviceId);
				} else {
					const amplitudeInterval = window.setInterval(() => {
						if (
							window.amplitude?.getInstance()?._isInitialized &&
							typeof window.amplitude.setDeviceId === "function"
						) {
							window.clearInterval(amplitudeInterval);
							window.clearTimeout(amplitudeTimeLimit);
							window.amplitude.setDeviceId(deviceId);
						}
					}, 100);

					const amplitudeTimeLimit = window.setTimeout(() => {
						window.clearInterval(amplitudeInterval);
						// eslint-disable-next-line no-console
						console.error("Could not access Amplitude.");
					}, 10000);
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error("Unknown Amplitude/Tealium cookie problem.");
		}
	};

	const initBancoEstadoBar = (category: number) => {
		const mainContent = DomCrawlingHelper.getElemByClass(document.body, "site-content");

		if (!mainContent || (category !== 5 && category !== 6)) {
			return;
		}

		mainContent.classList.add("dg-bancoe-bar-open");
		setIsBancoEstadoBarOpened(true);
	};

	const validateRestrictedUrl = () => {
		const pathElements = window.location.href.split("/");

		if (pathElements.length < 5) {
			window.location.href = "/cl/es";
			return;
		}

		const countryKey = 3;
		const languageKey = 4;

		if (pathElements[countryKey].toLowerCase() !== "cl" || pathElements[languageKey].toLowerCase() !== "es") {
			pathElements[countryKey] = "cl";
			pathElements[languageKey] = "es";

			const newUrl = pathElements.join("/");

			window.location.href = newUrl;
		}
	};

	const isNewBancoEstadoCategory = (category: number) => category === 5 || category === 6;

	const getUserTypeForLoginInfoModal = (userRoleCode: string): PcraLoginInfoModalType =>
		userRoleCode === "PECG" ? "gestor" : userRoleCode === "PECD" ? "delegado" : "none";

	// EVENT HANDLERS

	const handleScroll = () => {
		const st = window.scrollY;

		if (Math.abs(lastScrollTop - st) < 0) return;

		if (
			st > lastScrollTop &&
			st > navbarHeight * 2 &&
			st <= navbarHeight * 4 &&
			(!mobileMenu.current || !mobileMenu.current.checked)
		) {
			setIsSemiCollapsed(true);
		} else if (
			st > lastScrollTop &&
			st > navbarHeight * 4 &&
			(!mobileMenu.current || !mobileMenu.current.checked)
		) {
			setIsCollapsed(true);
		} else if (st < lastScrollTop && st >= navbarHeight * 4) {
			setIsCollapsed(false);
		} else if ((st < lastScrollTop && st < navbarHeight * 4) || st === 0) {
			setIsCollapsed(false);
			setIsSemiCollapsed(false);
		}

		lastScrollTop = st;
	};

	const handleCheckinModalOpen = () => {
		cultureSelector.closeCountryList();
		setIsLoginWidgetOpen(false);
		setIsAmericanDropdownOpen(false);
		mobileMenu.current.checked = false;
		const type: AdminModalType = "checkin";
		PUB_SUBS.AdminModalOpened.publish({ modalType: type });
	};

	const handleAdminModalOpen = () => {
		cultureSelector.closeCountryList();
		setIsLoginWidgetOpen(false);
		setIsAmericanDropdownOpen(false);
		mobileMenu.current.checked = false;
		const type: AdminModalType = "admin";
		PUB_SUBS.AdminModalOpened.publish({ modalType: type });
	};

	const handleMobileMenuClick = () => {
		cultureSelector.closeCountryList();
		setIsLoginWidgetOpen(false);
		setIsBancoEstadoTabOpen(false);
	};

	const handleBancoEstadoTabClick = () => {
		setIsBancoEstadoTabOpen(!isBancoEstadoTabOpen);
		cultureSelector.closeCountryList();
		setIsLoginWidgetOpen(false);
	};

	const handleLoginWidgetClick = () => {
		mobileMenu.current.checked = false;
		setIsLoginWidgetOpen(!isLoginWidgetOpen);
		setIsBancoEstadoTabOpen(false);
		cultureSelector.closeCountryList();
	};

	const handleLogout = () => {
		tealiumLog({
			eventName: "user_logout",
			eventParams: {},
		});

		window.setTimeout(
			() => (window.location.href = `${props.bookingUrl}/Member/Logout?url=${props.absoluteAction}`),
			500
		);
	};

	const toggleBancoEstadoBar = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsBancoEstadoBarOpened(!isBancoEstadoBarOpened);

		const mainContent = DomCrawlingHelper.getElemByClass(document.body, "site-content");

		if (mainContent) mainContent.classList.toggle("dg-bancoe-bar-open");
	};

	useEffect(init, []);

	const americanUserData = useAmericanUserData({
		culture: props.culture,
		isAmericanDropdownOpen,
		isHeaderSemiCollapsed: isSemiCollapsed,
		setIsAmericanDropdownOpen,
		closeCountryDropdown: cultureSelector.closeCountryList,
		handleLogout,
		handleCheckinModalOpen,
		handleAdminModalOpen,
	});

	// TEMPLATES

	const logoTemplate = () => {
		const { language, country } = languageAndCountry();
		const baseUrl = `/${country}/${language}`;

		return html`
			<a class="dg-jetsmart-logo" href=${baseUrl}>
				<img src="/images/header/jetsmart-logo-colored.svg" alt="JetSMART" />
			</a>
		`;
	};
	const menuTemplate = () =>
		props.showMenu
			? html`
					<div class="menu-container">
						<input
							ref=${ref(mobileMenu)}
							type="checkbox"
							class="mobile-navigation"
							@click=${handleMobileMenuClick}
						/>
						<div class="right">${menuItemsTemplate()}</div>
						<div class="mobile-navigation closed">${i18next.t("layout-menu")}</div>
						<div class="mobile-navigation opened">${i18next.t("layout-close")}</div>
						${cultureSelector.htmlTemplate()}
					</div>
			  `
			: "";

	const adminMenuTemplate = () =>
		props.showAdminMenu
			? html`
					<ul class="hidden-xs ml-4 flex flex-wrap gap-y-1">
						<li><a href="#stations">Stations</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#navitaire">Navitaire</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#dynamic">Dynamic Settings</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#promocode">Promo Codes</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#country">Default Country</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#timetable">Timetable</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#sitemap">Sitemap</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#widgets">Widgets</a></li>
						<span class="mx-2 block">|</span>
						<li><a href="#pages">Pages</a></li>
					</ul>
			  `
			: "";

	const menuItemsTemplate = () => html`
		<ul
			class=${classNames("login-container", {
				"american-mobile-menu": userInfo?.AmericanAirlines?.IsMember,
			})}
		>
			<li>
				<a @click=${handleCheckinModalOpen} data-test-id=${TestIdDictionary.Menu.CheckinOpener}>
					<span>${i18next.t("layout-check-in")}</span>
				</a>
			</li>
			<li>
				<a @click=${handleAdminModalOpen}>
					<span>${i18next.t("layout-manage-trip")}</span>
				</a>
			</li>
			${menuForAnonymousUsersTemplate()} ${menuForLoggedInUsersTemplate()}
		</ul>
	`;

	const bancoEstadoMenuOpenerTemplate = () => html`
		<a class=${classNames("dg-header-dropdown-label", { opened: isBancoEstadoTabOpen })}>
			<span class="dg-header-dropdown-label-title">
				${i18next.t("layout-login-bancoe")} <span>BancoEstado</span>
			</span>
		</a>
	`;

	const bancoEstadoMenuDropdownTemplate = () => html`
		<div class=${classNames("dg-header-dropdown-list", { opened: isBancoEstadoTabOpen })}>
			<a href=${bancoEstadoLoginUrl()}> ${i18next.t("layout-register")} </a>
			<a href=${bancoEstadoLoginUrl()}> ${i18next.t("layout-login")} </a>
		</div>
	`;

	const bancoEstadoMenuTemplate = () =>
		props.isBancoEstadoOn && props.culture.toLowerCase() === "es-cl"
			? html`
					<li class="dg-header-dropdown-opener" @click=${handleBancoEstadoTabClick}>
						${bancoEstadoMenuOpenerTemplate()} ${bancoEstadoMenuDropdownTemplate()}
					</li>
			  `
			: "";

	const menuForAnonymousUsersTemplate = () =>
		!isLoggedIn
			? html`
					<li>
						<a data-test-id=${TestIdDictionary.Menu.LoginOpener} @click=${handleLoginWidgetClick}>
							<span>${i18next.t("layout-login")}</span>
						</a>
					</li>
					${bancoEstadoMenuTemplate()}
			  `
			: "";

	const menuForLoggedInUsersTemplate = () =>
		isLoggedIn
			? html`${bancoEstadoProfileTemplate()} ${profileTemplate()} ${peruCompraUserInfoTemplate()}
			  ${chileCompraUserInfoTemplate()} ${logoutTemplate()} ${americanUserData.htmlTemplate()}`
			: "";

	const bancoEstadoProfileTemplate = () => {
		const tempClassNames = classNames("user-data-banco-estado", {
			"be-pointer-6": userInfo?.BancoEstado?.Category === 6,
			"be-pointer-5": userInfo?.BancoEstado?.Category === 5,
			"pointer-squash": !isBancoEstadoBarOpened,
		});

		const imgUrl =
			userInfo?.BancoEstado?.Category === 6
				? "/images/banco-estado/logo-smart-plus-bg-gray.png"
				: userInfo?.BancoEstado?.Category === 5
				? "/images/banco-estado/logo-smart-bg-orange.png"
				: "";

		return isNewBancoEstadoCategory(userInfo?.BancoEstado?.Category)
			? html`
					<li class=${tempClassNames}>
						<a href=${profileUrl()}>
							<span class="dg-username">
								${imgUrl ? html`<img src=${imgUrl} />` : ""} ${userName} ${bancoEstadoArrowTemplate()}
							</span>
						</a>
					</li>
			  `
			: "";
	};

	const bancoEstadoArrowTemplate = () =>
		html`
			<i
				class=${classNames("hidden-sm-down jsh-icon jsh-chevron-right point-down", {
					"point-up": !isBancoEstadoBarOpened,
				})}
				@click=${toggleBancoEstadoBar}
			></i>
		`;

	const profileTemplate = () => {
		if (
			userInfo &&
			(isNewBancoEstadoCategory(userInfo.BancoEstado?.Category) ||
				userInfo.PeruCompra?.IsAdmin ||
				userInfo.PeruCompra?.IsMember ||
				userInfo.ChileCompra?.IsMember ||
				userInfo.AmericanAirlines?.IsMember)
		) {
			return "";
		}

		return html`
			<li class="user-data">
				<a href=${profileUrl()}>
					<span
						class=${classNames("dg-username", {
							"dg-banco-estado-name": userInfo?.BancoEstado?.Category > 0,
						})}
					>
						${userName}
					</span>
					${employeeOrgTemplate()} ${cug2OrgNameTemplate()} ${bancoEstadoOrgNameTemplate()}
				</a>
			</li>
		`;
	};

	const peruCompraUserInfoTemplate = () => {
		const peruCompraProfileUrl = `${props.bookingUrl}/V2Agency/Summary?culture=${props.culture}&url=${props.absoluteAction}`;
		const availableAmount = numberFormatter({
			amount: Number(userInfo?.PeruCompra?.Amount) || 0,
			culture: props.culture,
			currency: "USD",
		});

		return userInfo?.PeruCompra?.IsAdmin || userInfo?.PeruCompra?.IsMember
			? html`
					<li class="user-data">
						<a href=${peruCompraProfileUrl}>
							<span class="dg-username">${userName}</span>
							<span class="orgname">
								${userInfo?.PeruCompra?.IsAdmin ? i18next.t("Gestor") : i18next.t("Delegado")} /
								${i18next.t("Monto {{-availableAmount}}", {
									availableAmount,
								})}
							</span>
						</a>
					</li>
			  `
			: "";
	};

	const chileCompraUserInfoTemplate = () => {
		const chileCompraProfileUrl = `${props.bookingUrl}/V2Agency/AgencyProfile?culture=${props.culture}&url=${props.absoluteAction}`;

		return userInfo?.ChileCompra?.IsMember
			? html`
					<li class="user-data">
						<a href=${chileCompraProfileUrl}>
							<span class="text-base font-bold uppercase leading-none text-[#00aec7]">${userName}</span>
							<span class="inline-block pt-1 !text-xs uppercase leading-none">
								${i18next.t("supervisor")}
							</span>
						</a>
					</li>
			  `
			: "";
	};

	const employeeOrgTemplate = () =>
		userInfo?.RoleCode === "EMPL" ? html`<span class="orgname">Team JetSMART</span>` : "";

	const cug2OrgNameTemplate = () =>
		userInfo?.OrganizationName ? html`<span class="orgname">${userInfo?.OrganizationName}</span>` : "";

	const bancoEstadoOrgNameTemplate = () =>
		userInfo?.BancoEstado?.Category > 0
			? html`<span class="dg-banco-estado-org-name">${i18next.t("layout-login-bancoe-orgname")}</span>`
			: "";

	const logoutTemplate = () =>
		!userInfo?.AmericanAirlines?.IsMember
			? html`
					<li>
						<a href="#" @click=${handleLogout} data-test-id=${TestIdDictionary.Menu.LogoutOpener}>
							<span>${i18next.t("layout-logout")}</span>
						</a>
					</li>
			  `
			: "";

	return html`
		<header
			ref=${ref(root)}
			class=${classNames("main-header", { "collapsed": isCollapsed, "semi-collapsed": isSemiCollapsed })}
		>
			<div ref=${ref(navBar)} class="nav-bg delayed">
				<section class="nav-container full-width">
					${logoTemplate()} ${menuTemplate()} ${adminMenuTemplate()}
				</section>
			</div>
			${loginWidget.htmlTemplate()}
			<ac-bancoe-bar .isBarOpen=${isBancoEstadoBarOpened}></ac-bancoe-bar>
		</header>
	`;
};
