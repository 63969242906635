import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { CLASS_NAMES } from "Services/ClassNames";
import url from "url";
import { usePaxSelector } from "./pax-selector/usePaxSelector";
import { useDateSelector } from "./date-selector/useDateSelector";
import { useEffect, useMemo, useState } from "Shared/haunted/CustomHooks";
import {
	BRASILIAN_CULTURE_CODE,
	COLOMBIA_COUNTRY_CODE,
	COMMON_DAYJS_FORMAT,
	FLIGHT_SELECT_URL,
	USA_CULTURE_CODE,
	USA_DOLLAR_CODE,
	WEB_ANONYMOUS_ROLE_CODE,
	WIDGETS,
} from "Services/constants";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { getParsedProperty, getRequestUrl, toBoolean } from "Services/common";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { ApiStationSettings } from "Shared/models/ApiStationSettings";
import { JetSmartEvent } from "Services/eventbus/JetSmartEvent";
import { validate } from "Services/form-validation";
import SearchRequest from "Shared/models/SearchRequest";
import { useRouteSelector } from "./route-selector/useRouteSelector";
import { useTripTypeSelector } from "./trip-type-selector/useTripTypeSelector";
import { usePromoCodeBox } from "./promo-code-box/usePromoCodeBox";
import { ref } from "Components/directives/ref";
import { tealiumLogSearchClick } from "ComponentHelpers/SearchboxTealiumHelpers";
import { useNavitaireSettings } from "ComponentHelpers/useNavitaireSettings";
import { ApiCultureSettings } from "ComponentModels/ApiCultureSettings";
import classNames from "classnames";
import { usePreviousSearch } from "./usePreviousSearch";
import { tealiumLog, getTealiumDeviceType } from "Services/TealiumHelpers";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { useReduxState } from "Shared/redux/useReduxState";

export const useShadowDOM = false;
export const name = "bb-searchbox";

export const observedAttributes: (keyof Attributes)[] = [
	"culture-settings",
	"culture",
	"currency",
	"dynamic-settings",
	"station-settings",
	"timetable-max-months-fallback",
];

type TabType = "flights" | "insurance" | "hotels" | "cars" | "transfers";

const REDEMPTION_MILES_ID = "redemptionMiles";

interface Tab {
	iconClass: string;
	label: string;
	type: TabType;
	onClick: () => void;
}

export interface Attributes {
	"culture-settings": string;
	"culture": string;
	"currency": string;
	"dynamic-settings": string;
	"station-settings": string;
	"timetable-max-months-fallback": string;
}

export interface Props {
	culture: string;
	cultureSettings: ApiCultureSettings;
	currency: string;
	dynamicSettings: DynamicSettings;
	stationSettings: ApiStationSettings;
	timetableMaxMonthsFallback: number;
}

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		culture: host.culture,
		cultureSettings:
			host.cultureSettings && typeof host.cultureSettings === "string"
				? getParsedProperty<ApiCultureSettings>(host.cultureSettings)
				: undefined,
		currency: host.currency,
		dynamicSettings:
			host.dynamicSettings && typeof host.dynamicSettings === "string"
				? getParsedProperty<DynamicSettings>(host.dynamicSettings)
				: undefined,
		stationSettings:
			host.stationSettings && typeof host.stationSettings === "string"
				? getParsedProperty<ApiStationSettings>(host.stationSettings)
				: undefined,
		timetableMaxMonthsFallback:
			host.timetableMaxMonthsFallback && typeof host.timetableMaxMonthsFallback === "string"
				? parseInt(host.timetableMaxMonthsFallback)
				: undefined,
	};

	const [userInfo] = useReduxState("userInfo");

	const navitaireSettings = useNavitaireSettings();

	const form = useRef<HTMLFormElement>(undefined);

	const [displayedTab, setDisplayedTab] = useState<TabType>("flights");
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const [isRedemptionCheckboxChecked, setIsRedemptionCheckboxChecked] = useState<boolean>(null);

	const isCug2User = () => Boolean(userInfo?.OrganizationName);

	const isChileCompraUser = () => userInfo?.ChileCompra?.IsMember;

	const isPeruCompraUser = () => userInfo?.PeruCompra?.IsMember || userInfo?.PeruCompra?.IsAdmin;

	const showRedemptionMiles = () =>
		window.JetSmart?.DynamicSettings?.IsAmericanOn && !isCug2User() && !isChileCompraUser() && !isPeruCompraUser();

	const promoCodeBox = usePromoCodeBox({
		culture: props.culture,
		dynamicSettings: props.dynamicSettings,
		isInFocus: !isCollapsed,
		isRedemptionCheckboxChecked,
	});

	const startLoading = () => {
		const loaderInstance = window.newRtLoader(".dg-for-loader");
		loaderInstance.startLoader();
		return loaderInstance;
	};

	const stopLoading = (loader: any) => {
		if (loader) {
			loader.stopLoader();
			loader.destroy();
		}
	};

	const routeSelector = useRouteSelector({
		culture: props.culture,
		currentPromoCode: promoCodeBox.promoCode,
		defaultRouteCountrySettings: props.dynamicSettings.DefaultRouteCountrySettings,
		showRedemptionMiles: showRedemptionMiles(),
		isRedemptionCheckboxChecked,
		setIsCollapsed,
		startLoading,
		stopLoading,
	});

	const tripTypeSelector = useTripTypeSelector({ isHidden: isCollapsed });

	const datePicker = useDateSelector({
		culture: props.culture,
		isHidden: isCollapsed,
		isOneWay: tripTypeSelector.numberOfJourneys === 1,
		selectedDestination: routeSelector.selectedDestination?.information?.code,
		selectedOrigin: routeSelector.selectedOrigin?.information?.code,
		usePrices: props.dynamicSettings.ShowFarePricesCultures,
		timetableMaxMonthsFallback: props.timetableMaxMonthsFallback,
		showRedemptionMiles: showRedemptionMiles(),
		isRedemptionCheckboxChecked,
		startLoading,
		stopLoading,
	});

	const paxSelector = usePaxSelector({
		culture: props.culture,
		isFlightDomesticColombia:
			routeSelector?.selectedOriginCountryCode === COLOMBIA_COUNTRY_CODE &&
			routeSelector?.selectedDestinationCountryCode === COLOMBIA_COUNTRY_CODE,
		showRedemptionMiles: showRedemptionMiles(),
		isRedemptionCheckboxChecked,
	});

	const previousSearch = usePreviousSearch();

	const showReloadLink = useMemo(
		() => Boolean(previousSearch.load()) && (!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
		[isRedemptionCheckboxChecked, userInfo?.AmericanAirlines?.IsMember]
	);

	// Helpers

	const getUserRoleCode = () =>
		userInfo?.RoleCode && userInfo?.RoleCode !== WEB_ANONYMOUS_ROLE_CODE ? userInfo?.RoleCode : "WebAnonymous";

	const logHomePageLoaded = () => {
		const country = window.location.pathname.slice(-6, -4).toUpperCase();
		const language = window.location.pathname.slice(-3, -1).toLowerCase();

		let eventParams: any = {
			culture: `${language}-${country}`,
			device: getTealiumDeviceType(),
			role_code: getUserRoleCode(),
		};

		if (userInfo?.ProgramCodesWithLevels?.length > 0) {
			eventParams = {
				...eventParams,
				program_code: userInfo.ProgramCodesWithLevels.reduce((acc, p) => `${acc}|${p.ProgramCode}`, ""),
				program_level: userInfo.ProgramCodesWithLevels.reduce((acc, p) => `${acc}|${p.ProgramLevel}`, ""),
			};
		}

		// DEVNOTE This is here because JET-9907
		tealiumLog({
			eventName: "homepage_dom_loaded",
			eventParams,
			updateRealUdo: true,
		});
	};

	const init = async () => {
		if (!userInfo?.RoleCode) return;

		logHomePageLoaded();

		previousSearch.refresh();

		await navitaireSettings.init({
			culture: props.culture,
			loadedValues: {
				abTestSettings: window.JetSmart.AbTestSettings,
				cultureSettings: props.cultureSettings,
				currency: props.currency,
				stationSettings: props.stationSettings,
			},
		});
	};

	const getSearchRequest = () => {
		const cultureElements = props.culture.split("-", 2);
		const countryIndex = 1;
		const rc = cultureElements.length === 2 ? cultureElements[countryIndex] : "";
		const isRedemptionSearch =
			showRedemptionMiles() && isRedemptionCheckboxChecked && userInfo?.AmericanAirlines?.IsMember;

		let req: SearchRequest = {
			o1: routeSelector.selectedOrigin.information.code,
			d1: routeSelector.selectedDestination.information.code,
			dd1: datePicker.currentDeparture.format(COMMON_DAYJS_FORMAT),
			ADT: paxSelector.passengers.adults + paxSelector.passengers.teens,
			CHD: paxSelector.passengers.children,
			inl: paxSelector.passengers.infants,
			r: false,
			s: true,
			mon: true,
			cur: !isRedemptionSearch ? navitaireSettings.value.currency : USA_DOLLAR_CODE,
			culture: props.culture,
			pc: !isRedemptionSearch ? promoCodeBox.promoCode : "",
			rc,
			selectedPriceOutbound: datePicker.selectedPriceOutbound,
			selectedPriceInbound: "",
			isRedemptionFlow: isRedemptionSearch,
		};

		if (tripTypeSelector.numberOfJourneys === 2) {
			req = {
				...req,
				dd2: datePicker.currentReturn.format(COMMON_DAYJS_FORMAT),
				selectedPriceInbound: datePicker.selectedPriceInbound,
				r: true,
			};
		}

		return req;
	};

	const redirectToQueueItUrl = (requestUrl: string) => {
		const newLocationUrl = encodeURIComponent(
			url.resolve(window.JetSmart.Settings.BookingUrl, `${FLIGHT_SELECT_URL}${requestUrl}`)
		);

		window.location.href = window.JetSmart.DynamicSettings.QueueItPrefix + newLocationUrl;
	};

	const redirectToRegularUrl = (requestUrl: string) => {
		location.href = url.resolve(window.JetSmart.Settings.BookingUrl, `${FLIGHT_SELECT_URL}${requestUrl}`);
	};

	const raiseEventBusEvent = (id: string) => {
		window.eventBus.raiseEvent({
			name: JetSmartEvent.SearchBoxTabClick,
			params: {
				id,
				event: "click",
				data: {},
			},
		});
	};

	const savePreviousSearch = () => {
		previousSearch.save({
			departureDate: datePicker.currentDeparture.format(COMMON_DAYJS_FORMAT),
			destination: routeSelector.selectedDestination.information.code,
			origin: routeSelector.selectedOrigin.information.code,
			passengers: {
				adults: paxSelector.passengers.adults,
				children: paxSelector.passengers.children,
				infants: paxSelector.passengers.infants,
				teens: paxSelector.passengers.teens,
			},
			returnDate: datePicker.currentReturn?.format(COMMON_DAYJS_FORMAT) || "",
		});
	};

	const showInsuranceTab = () =>
		props.dynamicSettings.DynamicInsuranceTabSettings.find(
			(s) => s.Culture.toLocaleLowerCase() === props.culture.toLocaleLowerCase()
		)?.IsActive;

	const getInsuranceTabUrl = () =>
		props.dynamicSettings.DynamicInsuranceTabSettings.find(
			(s) => s.Culture.toLocaleLowerCase() === props.culture.toLocaleLowerCase()
		)?.Url;

	// Event listeners

	const handleReloadSearch = async (e: MouseEvent) => {
		e.preventDefault();

		const value = previousSearch.load();

		if (!value) return;

		try {
			const shouldUpdateRoute =
				value.origin !== routeSelector.selectedOrigin?.information?.code ||
				value.destination !== routeSelector.selectedDestination?.information?.code;

			const shouldUpdateDates =
				value.departureDate !== datePicker.currentDeparture?.format(COMMON_DAYJS_FORMAT) ||
				value.returnDate !== datePicker.currentReturn?.format(COMMON_DAYJS_FORMAT);

			if (shouldUpdateRoute) {
				routeSelector.updateOnSearchReload(value.origin, value.destination);
			}

			if (shouldUpdateRoute || shouldUpdateDates) {
				await datePicker.updateOnSearchReload(
					value.origin,
					value.destination,
					value.departureDate,
					value.returnDate
				);
			}

			tripTypeSelector.updateOnSearchReload(value.returnDate ? 2 : 1);
			paxSelector.updateOnSearchReload(value.passengers);
		} catch {
			// Do nothing
		}
	};

	const handleFormSubmit = (e: MouseEvent) => {
		e.preventDefault();

		tealiumLogSearchClick({
			adults: paxSelector.passengers.adults,
			children: paxSelector.passengers.children,
			culture: props.culture,
			departureDate: datePicker.currentDeparture,
			destinationCode: routeSelector.selectedDestination?.information.code,
			infants: paxSelector.passengers.infants,
			numberOfJourneys: tripTypeSelector.numberOfJourneys,
			market:
				routeSelector.selectedDestinationCountryCode !== routeSelector.selectedOriginCountryCode
					? "INTER"
					: `DOM-${routeSelector.selectedOriginCountryCode}`,
			originCode: routeSelector.selectedOrigin?.information.code,
			preloadedPromoCode: promoCodeBox.predefinedPromoCode,
			promoCode: promoCodeBox.promoCode,
			returnDate: datePicker.currentReturn,
			teens: paxSelector.passengers.teens,
		});

		const validateForm = validate(form.current);
		const validateRoute = routeSelector.validate();
		const validateDates = datePicker.validate();
		const validatePaxSelector = paxSelector.validate();
		const validatePromoCode = promoCodeBox.validate();

		if (validateForm && validatePaxSelector && validatePromoCode && validateRoute && validateDates) {
			if (promoCodeBox.promoCode && isRedemptionCheckboxChecked && !userInfo?.AmericanAirlines?.IsMember) {
				PUB_SUBS.RedemptionNoPromoCodeModalOpened.publish({});
				return;
			}

			if (isRedemptionCheckboxChecked && !userInfo?.AmericanAirlines?.IsMember) {
				window.location.href = window.JetSmart?.AmericanAirlinesSettings?.LoginAddress;
				return;
			}

			startLoading();

			savePreviousSearch();

			const request = getSearchRequest();
			const requestUrl = getRequestUrl(request);
			const isQueueItOn = toBoolean(window.JetSmart.DynamicSettings.IsQueueItOn);

			if (isQueueItOn) {
				redirectToQueueItUrl(requestUrl);
			} else {
				redirectToRegularUrl(requestUrl);
			}
		}
	};

	const handleFlightTabSelect = () => {
		setDisplayedTab("flights");
		raiseEventBusEvent("searchbox.flightsTab");
	};

	const handleInsuranceTabSelect = () => {
		setDisplayedTab("insurance");
		raiseEventBusEvent("searchbox.insuranceTab");
	};

	const handleHotelTabSelect = () => {
		setDisplayedTab("hotels");
		raiseEventBusEvent("searchbox.hotelsTab");
	};

	const handleCarTabSelect = () => {
		setDisplayedTab("cars");
		raiseEventBusEvent("searchbox.rentalcarsTab");
	};

	const handleTransferTabSelect = () => {
		setDisplayedTab("transfers");
		raiseEventBusEvent("searchbox.transfersTab");
	};

	useEffect(() => {
		setIsRedemptionCheckboxChecked(userInfo?.AmericanAirlines?.IsMember);
	}, [userInfo?.RoleCode]);

	useEffect(init, [userInfo?.RoleCode]);

	// Templates

	const reloadLinkTemplate = () =>
		showReloadLink
			? html`
					<div class="m-0 flex w-full items-center justify-center px-0 pb-0 pt-2 text-center">
						<span
							class="cursor-pointer text-sm font-bold leading-none text-brand-secondary underline hover:text-brand-primary"
							@click=${handleReloadSearch}
						>
							&#8635;&nbsp;${i18next.t("Rearmar mi última búsqueda")}
						</span>
					</div>
			  `
			: "";

	const flightTabContentTemplate = () => html`
		<form ref=${ref(form)} @click=${() => setIsCollapsed(false)} novalidate>
			${routeSelector.htmlTemplate()} ${tripTypeSelector.htmlTemplate()} ${datePicker.htmlTemplate()}

			<div class=${classNames("dg-clear-on-mobile", { "hidden-xs": isCollapsed })}>
				${paxSelector.htmlTemplate()} ${promoCodeBox.htmlTemplate()}
			</div>

			${reloadLinkTemplate()} ${redemptionMilesCheckboxTemplate()}

			<div class="dg-btn-container">
				<button
					class="dg-rounded-primary-btn"
					@click=${handleFormSubmit}
					data-test-id=${TestIdDictionary.SubmitSearchButton}
				>
					<i class="jsh-icon jsh-search"></i>
					<span>${i18next.t("search-box-submit")}</span>
				</button>
			</div>
		</form>
	`;

	const redemptionMilesCheckboxTemplate = () =>
		showRedemptionMiles()
			? html`<div class="dg-redemption-checkbox-container">
					<div class="dg-redemption-checkbox">
						<input
							type="checkbox"
							id=${REDEMPTION_MILES_ID}
							autocomplete="off"
							?checked=${isRedemptionCheckboxChecked}
							@click=${() => setIsRedemptionCheckboxChecked(!isRedemptionCheckboxChecked)}
						/>
						<label for=${REDEMPTION_MILES_ID}>${i18next.t("Use AAdvantage® miles")}</label>
					</div>
			  </div>`
			: "";

	const flightTabTemplate = () => html`
		<div
			class=${classNames("dg-tab dg-flights dg-for-loader", {
				"hidden": displayedTab !== "flights",
				"collapsed": isCollapsed,
				"with-reload": showReloadLink,
				"with-redemption-miles-and-promo-code":
					showRedemptionMiles() && (!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
				"with-redemption-miles": showRedemptionMiles() && isRedemptionCheckboxChecked,
			})}
		>
			${flightTabContentTemplate()}
		</div>
	`;

	const handleInsuranceButtonClick = () => {
		window.open(getInsuranceTabUrl(), "_blank");
	};

	const insuranceHeaderTemplate = () => html`
		<div class="dg-insurance-header ">
			<i class="jsh-icon jsh-circle-hand-shield dg-insurance-title-icon"></i>
			<div class="dg-insurance-title">${i18next.t("search-box-insurance-title")}</div>
		</div>
	`;

	const insuranceTabTextTemplate = () => html`
		<div class="mb-6 flex w-full flex-col gap-y-4">
			<div class="dg-insurance-text">${i18next.t("search-box-insurance-text-1")}</div>
			<div class="dg-insurance-text">${i18next.t("search-box-insurance-text-2")}</div>
			<div class="dg-insurance-text">${i18next.t("search-box-insurance-text-3")}</div>
			<div class="dg-insurance-text">${i18next.t("search-box-insurance-text-4")}</div>
		</div>
	`;

	const insuranceTabButtonTemplate = () => {
		const image =
			props.culture.toLocaleLowerCase() === USA_CULTURE_CODE
				? "chubb-logo-en.svg"
				: props.culture.toLocaleLowerCase() === BRASILIAN_CULTURE_CODE
				? "chubb-logo-pt.svg"
				: "chubb-logo-es.svg";

		return html`
			<div class="dg-insurance-btn-container">
				<button class="dg-rounded-primary-btn dg-insurance-btn" @click=${handleInsuranceButtonClick}>
					${i18next.t("search-box-insurance-button")}
				</button>

				<img class="dg-insurance-logo" src=${`/images/header/${image}`} />
			</div>
		`;
	};
	const insuranceTabContentTemplate = () => html`
		<div class="h-full w-full px-4 py-6">
			${insuranceHeaderTemplate()} ${insuranceTabTextTemplate()}${insuranceTabButtonTemplate()}
		</div>
	`;

	const insuranceTabTemplate = () =>
		showInsuranceTab()
			? html` <div
					class=${classNames("dg-tab dg-insurance", {
						"hidden": displayedTab !== "insurance",
						"with-redemption-miles-and-promo-code":
							showRedemptionMiles() &&
							(!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
					})}
			  >
					${insuranceTabContentTemplate()}
			  </div>`
			: "";

	const hotelsTabTemplate = () => html`
		<div
			class=${classNames("dg-tab dg-hotels", {
				"hidden": displayedTab !== "hotels",
				"with-redemption-miles-and-promo-code":
					showRedemptionMiles() && (!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
			})}
		>
			<div id="hotel_container"></div>
		</div>
	`;

	const carsTabTemplate = () => {
		const carUrl = WIDGETS.get(props.culture.toLowerCase())?.carUrl || WIDGETS.get("default")?.carUrl;

		return html`
			<div
				class=${classNames("dg-tab dg-cars", {
					"hidden": displayedTab !== "cars",
					"with-redemption-miles-and-promo-code":
						showRedemptionMiles() &&
						(!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
				})}
			>
				<iframe src=${carUrl} height="432" width="100%" scrolling="no" frameborder="0"></iframe>
			</div>
		`;
	};

	const transfersTabTemplate = () => {
		const transferUrl =
			WIDGETS.get(props.culture.toLowerCase())?.transferUrl || WIDGETS.get("default")?.transferUrl;

		return html`
			<div
				class=${classNames("dg-tab dg-transfers", {
					"hidden": displayedTab !== "transfers",
					"with-redemption-miles-and-promo-code":
						showRedemptionMiles() &&
						(!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
				})}
			>
				<div class="transfer-credit">${i18next.t("search-box-transfer")} <span>Cartrawler</span></div>
				<iframe src=${transferUrl} height="432" width="100%" scrolling="no" frameborder="0"></iframe>
			</div>
		`;
	};

	const tabSelectorTemplate = (tab: Tab) => html`
		<li>
			<label class=${classNames({ [CLASS_NAMES.active]: tab.type === displayedTab })} @click=${tab.onClick}>
				<i class=${classNames("jsh-icon", { [tab.iconClass]: true })}></i>
				<span>${tab.label}</span>
			</label>
		</li>
	`;

	const tabs = (): Tab[] => {
		let list: Tab[] = [
			{
				type: "flights",
				label: i18next.t("search-box-tab-1"),
				iconClass: "jsh-plane",
				onClick: handleFlightTabSelect,
			},
			{
				type: "hotels",
				label: i18next.t("search-box-tab-3"),
				iconClass: "jsh-bed",
				onClick: handleHotelTabSelect,
			},
			{
				type: "cars",
				label: i18next.t("search-box-tab-4"),
				iconClass: "jsh-car",
				onClick: handleCarTabSelect,
			},
			{
				type: "transfers",
				label: i18next.t("search-box-tab-5"),
				iconClass: "jsh-van",
				onClick: handleTransferTabSelect,
			},
		];

		if (showInsuranceTab()) {
			list = [
				list[0],
				{
					type: "insurance",
					label: i18next.t("search-box-tab-2"),
					iconClass: "jsh-plane-shield",
					onClick: handleInsuranceTabSelect,
				},
				...list.slice(1),
			];
		}

		if (userInfo?.ChileCompra?.IsMember) {
			list = list.filter((tab) => tab.type === "flights");
		}

		return list;
	};

	// DEVNOTE The refactor is coming...
	return html`
		<div class="searchbox-container" data-test-id=${TestIdDictionary.SearchboxContainer}>
			<div class="searchbox">
				<nav
					class=${classNames("dg-tab-selector", {
						"bg-[#cacaca]": userInfo?.ChileCompra?.IsMember || !showInsuranceTab(),
						"with-insurance": showInsuranceTab(),
						"with-redemption-miles-and-promo-code":
							showRedemptionMiles() &&
							(!isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
					})}
				>
					<ul>
						${tabs().map((tab) => tabSelectorTemplate(tab))}
					</ul>
				</nav>
				${flightTabTemplate()} ${insuranceTabTemplate()} ${hotelsTabTemplate()} ${carsTabTemplate()}
				${transfersTabTemplate()}
			</div>
		</div>
	`;
};
