import { component } from "haunted";
import "@webcomponents/custom-elements";
import * as BancoEstadoBar from "Components/bancoe-bar/bancoe-bar";
import * as AdminModal from "Components/admin-modal/admin-modal";
import * as CultureNameSettings from "Components/admin/culture-name-settings";
import * as DynamicPromoCodes from "Components/admin/dynamic-promo-codes";
import * as DefaultCountries from "Components/admin/route-settings";
import * as InsuranceTab from "Components/admin/insurance-tab-settings";
import * as Searchbox from "Components/searchbox/searchbox";
import * as DcDatepicker from "Components/searchbox/date-selector/dc-datepicker";
import * as SmartTimer from "Components/smart-timer/smart-timer";
import * as MainMenu from "Components/main-menu/main-menu";
import * as PcraLoginInfoModal from "Components/pcra-login-info-modal/peru-compra-login-info-modal";
import * as ChileCompraLoginInfoModal from "Components/chile-compra-login-info-modal/chile-compra-login-info-modal";
import * as Cug3LoginHandler from "Components/login-handlers/cug3-login-handler";
import * as AALoginHandler from "Components/login-handlers/aa-login-handler";
import * as RedemptionNoPromoCodeModal from "Components/redemption-no-promo-code-modal/redemption-no-promo-code-modal";
import * as AmericanAirlinesTokenModal from "Components/american-airlines-token-modal/american-airlines-token-modal";

export function initCustomElements() {
	customElements.define(
		BancoEstadoBar.name,
		component<HTMLElement & BancoEstadoBar.Props>(BancoEstadoBar.Component, {
			useShadowDOM: BancoEstadoBar.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		AdminModal.name,
		component<HTMLElement & AdminModal.Attributes & AdminModal.Props>(AdminModal.Component, {
			useShadowDOM: AdminModal.useShadowDOM,
			observedAttributes: AdminModal.observedAttributes,
		})
	);

	customElements.define(
		Searchbox.name,
		component<HTMLElement & Searchbox.Attributes & Searchbox.Props>(Searchbox.Component, {
			useShadowDOM: Searchbox.useShadowDOM,
			observedAttributes: Searchbox.observedAttributes,
		})
	);

	customElements.define(
		DcDatepicker.name,
		component<HTMLElement & DcDatepicker.Properties>(DcDatepicker.Component, {
			useShadowDOM: DcDatepicker.useShadowDOM,
			observedAttributes: DcDatepicker.observedAttributes,
		})
	);

	customElements.define(
		DefaultCountries.name,
		component<HTMLElement & DefaultCountries.Props & DefaultCountries.Attributes>(DefaultCountries.Component, {
			useShadowDOM: DefaultCountries.useShadowDOM,
			observedAttributes: DefaultCountries.observedAttributes,
		})
	);

	customElements.define(
		InsuranceTab.name,
		component<HTMLElement & InsuranceTab.Props & InsuranceTab.Attributes>(InsuranceTab.Component, {
			useShadowDOM: InsuranceTab.useShadowDOM,
			observedAttributes: InsuranceTab.observedAttributes,
		})
	);

	customElements.define(
		DynamicPromoCodes.name,
		component<HTMLElement & DynamicPromoCodes.Props & DynamicPromoCodes.Attributes>(DynamicPromoCodes.Component, {
			useShadowDOM: DynamicPromoCodes.useShadowDOM,
			observedAttributes: DynamicPromoCodes.observedAttributes,
		})
	);

	customElements.define(
		CultureNameSettings.name,
		component<HTMLElement & CultureNameSettings.Props & CultureNameSettings.Attributes>(
			CultureNameSettings.Component,
			{
				useShadowDOM: CultureNameSettings.useShadowDOM,
				observedAttributes: CultureNameSettings.observedAttributes,
			}
		)
	);

	customElements.define(
		SmartTimer.name,
		component<HTMLElement & SmartTimer.Props & SmartTimer.Attributes>(SmartTimer.Component, {
			useShadowDOM: SmartTimer.useShadowDOM,
			observedAttributes: SmartTimer.observedAttributes,
		})
	);

	customElements.define(
		MainMenu.name,
		component<HTMLElement & MainMenu.Props & MainMenu.Attributes>(MainMenu.Component, {
			useShadowDOM: MainMenu.useShadowDOM,
			observedAttributes: MainMenu.observedAttributes,
		})
	);

	customElements.define(
		PcraLoginInfoModal.name,
		component<HTMLElement>(PcraLoginInfoModal.Component, {
			useShadowDOM: PcraLoginInfoModal.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		ChileCompraLoginInfoModal.name,
		component<HTMLElement>(ChileCompraLoginInfoModal.Component, {
			useShadowDOM: ChileCompraLoginInfoModal.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		Cug3LoginHandler.name,
		component<HTMLElement>(Cug3LoginHandler.Component, {
			useShadowDOM: Cug3LoginHandler.useShadowDOM,
			observedAttributes: [],
		})
	);

    customElements.define(
		AALoginHandler.name,
		component<HTMLElement>(AALoginHandler.Component, {
			useShadowDOM: AALoginHandler.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		RedemptionNoPromoCodeModal.name,
		component<HTMLElement>(RedemptionNoPromoCodeModal.Component, {
			useShadowDOM: RedemptionNoPromoCodeModal.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		AmericanAirlinesTokenModal.name,
		component<HTMLElement>(AmericanAirlinesTokenModal.Component, {
			useShadowDOM: AmericanAirlinesTokenModal.useShadowDOM,
			observedAttributes: [],
		})
	);
}
