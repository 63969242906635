import dayjs, { Dayjs } from "dayjs";
import * as UTC from "dayjs/plugin/utc";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import i18next from "i18next";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { html, useEffect } from "haunted";
import { useCountdown } from "./useCountDown";
import { useState } from "Shared/haunted/CustomHooks";
import { createHeaders } from "ComponentHelpers/HeaderHelper";
import {
	AMERICAN_AIRLINES_USER_ROLE,
	API_AMERICAN_AIRLINES_REFRESH_TOKEN_URL,
	LOGOUT_INLINE_URL,
} from "Services/constants";
import { useReduxState } from "Shared/redux/useReduxState";
dayjs.extend(CustomParseFormat);
dayjs.extend(UTC);

export const name = "ac-american-airlines-token-modal";
export const useShadowDOM = false;

const visibleCountdownBeforeEndInMinutes = 1;

export const Component: HauntedFunc<{}> = () => {
	const [userInfo] = useReduxState("userInfo");

	const finalCountdown = useCountdown({ minutes: visibleCountdownBeforeEndInMinutes, seconds: 0, interval: 1000 });

	const [timer, setTimer] = useState<any>(null);
	const [mode, setMode] = useState<"default" | "counting" | "ending" | "ended" | "error">("default");

	const init = async () => {
		if (
			!userInfo?.RoleCode ||
			!userInfo?.AmericanAirlines?.IsMember ||
			userInfo?.RoleCode !== AMERICAN_AIRLINES_USER_ROLE
		) {
			return;
		}

		const deadline = userInfo?.AmericanAirlines?.TokenValidTo;

		if (!deadline) {
			setMode("ended");
			return;
		}

		startCountdown(deadline);
	};

	const isOpen = mode !== "default" && mode !== "counting";

	const canRefresh = mode !== "ended" && mode !== "error";

	const parseTime = (time: string): Dayjs => {
		const retVal = dayjs.utc(time, "YYYY-MM-DD HH:mm:ss");
		return retVal;
	};

	const getCountdownTime = (time: dayjs.Dayjs): number => {
		const currentUtc = new Date().getTime();
		const deadlineUtc = time.valueOf();
		const difference = visibleCountdownBeforeEndInMinutes * 60 * 1000;
		const retVal = deadlineUtc - currentUtc - difference;

		return retVal;
	};

	const startCountdown = (deadline: string) => {
		window.clearTimeout(timer);
		const differenceInMilliseconds = getCountdownTime(parseTime(deadline));
		const visibleCountdownBeforeEndInMilliseconds = visibleCountdownBeforeEndInMinutes * 60 * 1000;

		if (differenceInMilliseconds <= visibleCountdownBeforeEndInMilliseconds) {
			setMode("ended");
		} else {
			setMode("counting");
			setTimer(window.setTimeout(startVisibleCountdown, differenceInMilliseconds));
		}
	};

	const startVisibleCountdown = () => {
		finalCountdown.resetCountdown();
		setMode("ending");
	};

	const refreshSession = async () => {
		const headers = createHeaders();

		const response = await fetch(
			`${window.JetSmart.Settings.BookingUrl}${API_AMERICAN_AIRLINES_REFRESH_TOKEN_URL}`,
			{
				method: "GET",
				headers,
				mode: "cors",
				credentials: "include",
			}
		);

		if (!response.ok) {
			setMode("error");
			return;
		}

		const responseJson = await response.json();
		startCountdown(responseJson.data);
	};

	const logOut = async () => {
		const headers = createHeaders();

		await fetch(`${window.JetSmart.Settings.BookingUrl}${LOGOUT_INLINE_URL}`, {
			method: "GET",
			headers,
			mode: "cors",
			credentials: "include",
		});
	};

	// Handlers

	const handleContinueButtonClick = async (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		await refreshSession();
	};

	const handleRestartButtonClick = async (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		await logOut();
		window.location.href = "/";
	};

	useEffect(async () => {
		if (finalCountdown.isCountdownFinished && isOpen && userInfo?.AmericanAirlines?.IsMember) {
			setMode("ended");
			await logOut();
		}
	}, [finalCountdown.isCountdownFinished, isOpen, userInfo?.RoleCode]);

	useEffect(init, [userInfo?.RoleCode]);

	//TEMPLATES

	const sessionWarningMessageTemplate = () => (canRefresh ? html` <div>${i18next.t("Session-Warning2")}</div> ` : "");

	const sessionRefreshButtonTemplate = () =>
		canRefresh
			? html`
					<button class="dg-rounded-primary-btn" @click=${handleContinueButtonClick}>
						${i18next.t("Session-RefreshButton")}
					</button>
			  `
			: "";

	const errorWarningTemplate = () =>
		mode === "error" ? html` <div>${i18next.t("Ha ocurrido un error.")}</div> ` : "";

	const sessionExpiredMessageTemplate = () =>
		mode === "ended" ? html` <div>${i18next.t("Session-Expired")}</div> ` : "";

	const startOverButtonTemplate = () =>
		!canRefresh
			? html`
					<button class="dg-rounded-primary-btn" @click=${handleRestartButtonClick}>
						${i18next.t("Session-StartOverButton")}
					</button>
			  `
			: "";

	const timerDisplayTemplate = () => {
		if (mode === "error") return "";

		if (mode === "ended") return html` <span class="ml-2">(00:00)</span>`;

		const remainingMinutes = finalCountdown.remainingMinutes.toString().padStart(2, "0");
		const remainingSeconds = finalCountdown.remainingSeconds.toString().padStart(2, "0");
		const remainingTimeString = `(${remainingMinutes}:${remainingSeconds})`;
		return html` <span class="ml-2">${remainingTimeString}</span> `;
	};

	const headerTemplate = () => html`
		<div
			class="flex items-center justify-start rounded-lg bg-[#def3f7] p-5 text-left text-[22px] font-black text-[#163a70]"
		>
			<i class="fas fa-exclamation-circle mr-5 text-[36px] text-[#59c3d9]"></i>
			${i18next.t("Session-Warning")} ${timerDisplayTemplate()}
		</div>
	`;

	return isOpen
		? html`
				<div class="dg-new-modal">
					<div class="dg-new-modal-content !w-[500px] max-w-[95%] p-3">
						${headerTemplate()}
						<div class="p-5">
							${sessionWarningMessageTemplate()} ${sessionExpiredMessageTemplate()}
							${errorWarningTemplate()}
							<div class="mt-[30px] flex w-full items-center justify-end">
								${sessionRefreshButtonTemplate()} ${startOverButtonTemplate()}
							</div>
						</div>
					</div>
				</div>
		  `
		: html``;
};
