import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { CLASS_NAMES } from "Services/ClassNames";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "Shared/haunted/CustomHooks";
import { COMMON_DAYJS_FORMAT, OPEN_CLASS_NAME } from "Services/constants";
import { ChangeDateEvent, ChangeSelectedPriceEvent } from "./dc-datepicker";
import { ref } from "Components/directives/ref";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import getShowFarePriceForCulture from "Shared/helpers/farePriceHelper";
import { useTimeTable } from "ComponentHelpers/useTimeTable";
import { usePane } from "../opening-pane/usePane";
import { Schedule } from "Shared/models/Schedule";
import classNames from "classnames";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs, { Dayjs } from "dayjs";
import { useReduxState } from "Shared/redux/useReduxState";
dayjs.extend(CustomParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface Props {
	culture: string;
	isHidden: boolean;
	isOneWay: boolean;
	selectedDestination: string;
	selectedOrigin: string;
	usePrices: ShowFarePrices;
	showRedemptionMiles: boolean;
	isRedemptionCheckboxChecked: boolean;
	startLoading: () => void;
	stopLoading: (loader: any) => void;
	timetableMaxMonthsFallback: number;
}

export const useDateSelector = (props: Props) => {
	const pane = usePane();
	const [userInfo] = useReduxState("userInfo");

	const datePane = useRef<HTMLDivElement>(undefined);
	const fromDate = useRef<HTMLInputElement>(undefined);
	const toDate = useRef<HTMLInputElement>(undefined);

	const [currentDeparture, setCurrentDeparture] = useState<Dayjs>(undefined);
	const [currentReturn, setCurrentReturn] = useState<Dayjs>(undefined);
	const [isValidated, setIsValidated] = useState<boolean>(false);
	const [selectedPriceInbound, setSelectedPriceInbound] = useState<string>("");
	const [selectedPriceOutbound, setSelectedPriceOutbound] = useState<string>("");
	const [forcedUpdateMode, setForcedUpdateMode] = useState<boolean>(false);

	const timetable = useTimeTable({
		destinationCode: props.selectedDestination,
		isOneWay: props.isOneWay,
		originCode: props.selectedOrigin,
		forcedUpdateMode,
		startLoading: props.startLoading,
		stopLoading: props.stopLoading,
	});

	const isOpen = useMemo(() => pane.mode === "departure" || pane.mode === "return", [pane.mode]);

	// HELPERS

	const onClickOutsideDate = (e: MouseEvent) => {
		const path = e.composedPath && e.composedPath();

		const isClickInside =
			path.indexOf(fromDate.current) > -1 ||
			path.indexOf(toDate.current) > -1 ||
			path.indexOf(datePane.current) > -1;

		if (!isClickInside && isOpen) {
			pane.set("none");
		}
	};

	const validate = () => {
		setIsValidated(true);
		return Boolean(currentDeparture) && (Boolean(currentReturn) || props.isOneWay);
	};

	const sortSchedulesForMin = (a: Schedule, b: Schedule) => (a.departureDate.isBefore(b.departureDate) ? -1 : 1);
	const sortSchedulesForMax = (a: Schedule, b: Schedule) => (a.departureDate.isBefore(b.departureDate) ? 1 : -1);

	const getMinDateOutbound = () => {
		const firstOutbound = timetable.outboundSchedules?.sort(sortSchedulesForMin)[0]?.departureDate;
		return firstOutbound?.isAfter(dayjs(), "day") ? dayjs(firstOutbound) : dayjs();
	};

	const getMinDateInbound = () => {
		const firstInbound = timetable.inboundSchedules?.sort(sortSchedulesForMin)[0]?.departureDate;
		return currentDeparture && firstInbound?.isAfter(dayjs(currentDeparture), "day")
			? firstInbound
			: dayjs(currentDeparture);
	};

	const getMaxDateOutbound = () => {
		const lastOutbound = timetable.outboundSchedules?.sort(sortSchedulesForMax)[0]?.departureDate;
		return lastOutbound ? dayjs(lastOutbound) : dayjs().add(props.timetableMaxMonthsFallback, "months");
	};

	const getMaxDateInbound = () => {
		const lastInbound = timetable.inboundSchedules?.sort(sortSchedulesForMax)[0]?.departureDate;
		return lastInbound ? dayjs(lastInbound) : dayjs().add(props.timetableMaxMonthsFallback, "months");
	};

	// EVENT HANDLERS

	const handleDateChange = (e: ChangeDateEvent) => {
		if (pane.mode === "departure") {
			setCurrentDeparture(dayjs(e.detail.date));
			pane.set(props.isOneWay ? "none" : "return");
		} else {
			setCurrentReturn(dayjs(e.detail.toDate));
			pane.set("none");
		}
	};

	const handlePriceChange = (e: ChangeSelectedPriceEvent) => {
		if (pane.mode === "departure") {
			setSelectedPriceOutbound(e.detail.price);
		} else {
			setSelectedPriceInbound(e.detail.price);
		}
	};

	const handleDepartureClick = (e: MouseEvent) => {
		e.preventDefault();

		pane.set("departure");
	};

	const handleReturnClick = (e: MouseEvent) => {
		e.preventDefault();

		pane.set("return");
	};

	const updateOnSearchReload = async (
		selectedOrigin: string,
		selectedDestination: string,
		departureDate: string,
		returnDate?: string
	) => {
		if (!departureDate) return;

		setForcedUpdateMode(true);
		const { newOutboundDates, newInboundDates } = await timetable.updateOnSearchReload(
			selectedOrigin,
			selectedDestination
		);

		setForcedUpdateMode(false);

		const parsedDeparture = dayjs(departureDate, COMMON_DAYJS_FORMAT);

		if (parsedDeparture.isBefore(dayjs(), "day")) return;

		if (
			parsedDeparture &&
			newOutboundDates?.length &&
			!newOutboundDates.some((s) => s.departureDate.isSame(parsedDeparture, "day"))
		) {
			setCurrentDeparture(undefined);
			return;
		}

		setCurrentDeparture(parsedDeparture);

		const parsedReturn = returnDate ? dayjs(returnDate, COMMON_DAYJS_FORMAT) : undefined;

		if (!parsedReturn) return;

		if (newInboundDates?.length && !newInboundDates.some((s) => s.departureDate.isSame(parsedReturn, "day"))) {
			setCurrentReturn(undefined);
			return;
		}

		if (parsedReturn.isSameOrAfter(parsedDeparture, "day")) setCurrentReturn(parsedReturn);
	};

	useEffect(() => {
		if (isOpen) {
			const handler = PUB_SUBS.DocumentClicked.subscribe((e) => onClickOutsideDate(e.mouseEvent));
			return () => handler?.unsubscribe();
		}
		return () => {};
	}, [fromDate?.current, toDate?.current, datePane?.current, isOpen]);

	useEffect(() => {
		if (props.isOneWay || (currentReturn && currentDeparture?.isAfter(currentReturn, "day"))) {
			setCurrentReturn(undefined);
		}
	}, [props.isOneWay, currentDeparture, currentReturn]);

	useEffect(() => {
		if (forcedUpdateMode) return;
		setCurrentDeparture(undefined);
		setCurrentReturn(undefined);
	}, [props.selectedOrigin, props.selectedDestination, timetable.outboundSchedules]);

	// TEMPLATES

	const calendarTemplate = () => {
		const value =
			pane.mode === "departure"
				? currentDeparture || ""
				: [currentDeparture || "", currentReturn || currentDeparture || ""];

		return isOpen
			? html`
					<dc-datepicker
						.culture=${props.culture}
						.isRange=${pane.mode === "return"}
						.max=${pane.mode === "departure" ? getMaxDateOutbound() : getMaxDateInbound()}
						.min=${pane.mode === "departure" ? getMinDateOutbound() : getMinDateInbound()}
						.availableDates=${pane.mode === "departure"
							? timetable.outboundSchedules
							: timetable.inboundSchedules}
						.metadata=${pane.mode === "departure" ? timetable.outboundMetadata : timetable.inboundMetadata}
						.value=${value}
						.usePrices=${getShowFarePriceForCulture(props.usePrices, props.culture)}
						.paneMode=${pane.mode}
						@changeDate=${handleDateChange}
						@changePrice=${handlePriceChange}
					></dc-datepicker>
			  `
			: "";
	};

	const datepickerTemplate = () => html`
		<div
			ref=${ref(datePane)}
			class=${classNames("dg-calendar", {
				[OPEN_CLASS_NAME]: isOpen,
				"with-redemption-miles-and-promo-code":
					props.showRedemptionMiles &&
					(!props.isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines?.IsMember),
			})}
		>
			<div class="hidden-sm-up dg-dp-closer" @click=${() => pane.set("none")}>&times;</div>
			${calendarTemplate()}
		</div>
	`;

	const htmlTemplate = () => {
		const isDepartureDisabled = !props.selectedOrigin || !props.selectedDestination;
		const departureText = forcedUpdateMode
			? ""
			: !isDepartureDisabled
			? currentDeparture?.format(COMMON_DAYJS_FORMAT) || ""
			: "";

		const isReturnDisabled =
			!props.selectedOrigin || !props.selectedDestination || !currentDeparture || props.isOneWay;

		const returnText = forcedUpdateMode
			? ""
			: props.isOneWay
			? i18next.t("search-box-oneway")
			: !isReturnDisabled
			? currentReturn?.format(COMMON_DAYJS_FORMAT) || ""
			: "";

		return html`
			<div class=${classNames("dg-time-selector", { "hidden-xs": props.isHidden })}>
				<input
					ref=${ref(fromDate)}
					class=${isValidated && !currentDeparture ? CLASS_NAMES.error : ""}
					autocomplete="disabled"
					readonly
					?disabled=${isDepartureDisabled}
					value=${departureText}
					.value=${departureText}
					data-test-id=${TestIdDictionary.Date.DepartureInput}
					@click=${handleDepartureClick}
				/>
				<input
					ref=${ref(toDate)}
					class=${isValidated && !currentReturn && !props.isOneWay ? CLASS_NAMES.error : ""}
					autocomplete="disabled"
					readonly
					?disabled=${isReturnDisabled}
					value=${returnText}
					.value=${returnText}
					data-test-id=${TestIdDictionary.Date.ReturnInput}
					@click=${handleReturnClick}
				/>
				<div class="dg-divider-arrow-time"></div>
				<label>${i18next.t("search-box-from-date")}</label>
				<label class="dg-return-date">${i18next.t("search-box-to-date")}</label>
			</div>
			${datepickerTemplate()}
		`;
	};

	return {
		currentDeparture,
		currentReturn,
		selectedPriceInbound,
		selectedPriceOutbound,
		htmlTemplate,
		updateOnSearchReload,
		validate,
	};
};
