import {
	AmericanAirlinesInfo,
	ApiUserInfo,
	BancoEstadoInfo,
	ChileCompraInfo,
	DiscountClubInfo,
	MappedUserInfo,
	PeruCompraInfo,
} from "Shared/models/UserInfo";

export const mapUserInfo = (apiUserInfo: ApiUserInfo): MappedUserInfo => ({
	AmericanAirlines:
		apiUserInfo.Type === "AmericanAirlinesInfo"
			? {
					AdvantageNumber: (apiUserInfo as AmericanAirlinesInfo).AdvantageNumber,
					EligibleForRedemption: true,
					IsMember: true,
					Miles: (apiUserInfo as AmericanAirlinesInfo).Miles,
					Tier: (apiUserInfo as AmericanAirlinesInfo).Tier,
					TokenValidTo: (apiUserInfo as AmericanAirlinesInfo).TokenValidTo,
			  }
			: undefined,
	BancoEstado:
		apiUserInfo.Type === "BancoEstadoInfo"
			? {
					Category: (apiUserInfo as BancoEstadoInfo).Category,
					FreeSeats: (apiUserInfo as BancoEstadoInfo).FreeSeats,
			  }
			: undefined,
	ChileCompra:
		apiUserInfo.Type === "ChileCompraInfo"
			? {
					IsMember: (apiUserInfo as ChileCompraInfo).IsMember,
			  }
			: undefined,
	Dc:
		apiUserInfo.Type === "DiscountClubInfo"
			? {
					IsGroupMember: (apiUserInfo as DiscountClubInfo).IsGroupMember,
					IsStandardMember: (apiUserInfo as DiscountClubInfo).IsStandardMember,
			  }
			: undefined,
	PeruCompra:
		apiUserInfo.Type === "PeruCompraInfo"
			? {
					Amount: (apiUserInfo as PeruCompraInfo).FormattedAmount,
					IsAdmin: (apiUserInfo as PeruCompraInfo).IsAdmin,
					IsMember: (apiUserInfo as PeruCompraInfo).IsMember,
			  }
			: undefined,

	Name: apiUserInfo.Name,
	DeviceId: apiUserInfo.DeviceId,
	OrganizationName: apiUserInfo.OrganizationName,
	ProgramCodesWithLevels: apiUserInfo.ProgramCodesWithLevels,
	RoleCode: apiUserInfo.RoleCode,
});
