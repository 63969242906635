import { useNavitaireSettings } from "ComponentHelpers/useNavitaireSettings";
import { useMemo, useState } from "Shared/haunted/CustomHooks";
import { html } from "lit-html";
import { isCultureEnabled, getSelectableCulturesInOrder, mapToCultureListItem } from "ComponentHelpers/CultureHelper";
import { useRef } from "haunted";
import { ref } from "Components/directives/ref";
import classNames from "classnames";
import { useReduxState } from "Shared/redux/useReduxState";

export interface Props {
	culture: string;
	languageAndCountry: (culture: string) => { language: string; country: string };
	onOpen: () => void;
}

export interface CultureListItem {
	culture: string;
	name: string;
}

export const useCultureSelector = (props: Props) => {
	const listElem = useRef<HTMLDivElement>(undefined);
	const navitaireSettings = useNavitaireSettings();

	const [isCountryListOpened, setIsCountryListOpened] = useState<boolean>(false);

	const [userInfo] = useReduxState("userInfo");

	const cultureList: CultureListItem[] = useMemo(() => {
		const list = window.JetSmart.DynamicSettings.DynamicCultureNameSettings || [];

		const selectedLanguage = props.languageAndCountry(props.culture).language;

		// Convert dynamic culture name settings to culture list items
		const newList = list.map((item) => {
			const name = selectedLanguage === "es" ? item?.Es : selectedLanguage === "en" ? item?.En : item?.Pt;

			return {
				culture: item?.Culture,
				name: name || "",
			};
		});

		return newList.filter((listItem) =>
			isCultureEnabled(navitaireSettings?.value?.cultureSettings, listItem.culture)
		);
	}, [navitaireSettings?.value?.cultureSettings]);

	const cultures = useMemo(
		() => getSelectableCulturesInOrder(navitaireSettings?.value?.cultureSettings, cultureList, props.culture),
		[cultureList, props.culture, navitaireSettings?.value?.cultureSettings]
	);

	// Helpers

	const canSwitchCulture = () =>
		navitaireSettings?.value?.cultureSettings?.Cultures?.length &&
		!userInfo?.BancoEstado?.Category &&
		!userInfo?.ChileCompra?.IsMember &&
		!userInfo?.PeruCompra?.IsAdmin &&
		!userInfo?.PeruCompra?.IsMember;

	// Events

	const handleCountryListOpen = () => {
		if (!canSwitchCulture()) return;

		setIsCountryListOpened(!isCountryListOpened);
		props.onOpen();
	};

	// Templates

	const cultureTemplate = (culture: CultureListItem) => html`
		<img class=${classNames("flag", `flag-${culture.culture.substring(3)}`)} />
		<span> ${culture.name}</span>
	`;

	const openCultureTemplate = () => {
		const selectedCulture = mapToCultureListItem(cultureList, props.culture);
		return cultureTemplate(selectedCulture);
	};

	const cultureOptionTemplate = (culture: CultureListItem) => {
		const { language, country } = props.languageAndCountry(culture.culture);
		const url = `/ChangeLanguage?newCountryCode=${country}&newLanguagecode=${language}`;

		return html`<a href=${url}>${cultureTemplate(culture)}</a>`;
	};

	const cultureDropdownTemplate = () =>
		canSwitchCulture()
			? html`
					<div
						ref=${ref(listElem)}
						class="cultures"
						style="max-height: ${isCountryListOpened ? `${listElem.current.scrollHeight}px` : "0"};"
					>
						<ul>
							${cultures.map(cultureOptionTemplate)}
						</ul>
					</div>
			  `
			: "";

	const htmlTemplate = () =>
		cultures.length > 0
			? html`
					<div class="dg-culture-dropdown-opener">
						<a
							@click=${handleCountryListOpen}
							class=${classNames("menu-button with-flag", {
								"opened": isCountryListOpened,
								"no-arrow": !canSwitchCulture(),
							})}
						>
							${openCultureTemplate()}
						</a>
						${cultureDropdownTemplate()}
					</div>
			  `
			: "";

	return { closeCountryList: () => setIsCountryListOpened(false), htmlTemplate };
};
