import { useEffect, useState } from "haunted";
import Immutable, { isCollection } from "immutable";
import { SimpleActionCreator } from "redux-act";
import { REDUCER_NAME, ReduxContext, StateMap, reducerActions } from "./ReduxContext";
import { NestedKeyOf, PickByNestedKeyOf } from "./ReducerTypes";

export const useReduxState = <PropertyName extends NestedKeyOf<StateMap>>(
	propertyName: PropertyName
): [PickByNestedKeyOf<StateMap, PropertyName>, (val: PickByNestedKeyOf<StateMap, PropertyName>) => void] => {
	const currentValue = () => ReduxContext.store.getState().getIn([REDUCER_NAME, ...propertyName.split(".")]);

	const toJsIfNeeded = (val: any) => (isCollection(val) ? (val as Immutable.Collection<any, any>).toJS() : val);

	const [data, setData] = useState(toJsIfNeeded(currentValue()));

	const dispatch = (val: PickByNestedKeyOf<StateMap, PropertyName>) => {
		const action = reducerActions.find((item) => item.propertyName === propertyName)
			.action as unknown as SimpleActionCreator<PickByNestedKeyOf<StateMap, PropertyName>>;
		ReduxContext.dispatch(action(val));
	};

	useEffect(() => {
		const currentVal = toJsIfNeeded(currentValue());
		setData(currentVal);
		let savedData = currentVal;

		return ReduxContext.store.subscribe(() => {
			const newData = ReduxContext.store.getState().getIn([REDUCER_NAME, ...propertyName.split(".")]);
			// Check is necessary because toJS is expensive in case of collections
			if (newData !== savedData) {
				savedData = newData;
				const currData = toJsIfNeeded(newData);
				setData(currData);
			}
		});
	}, []);

	return [data as PickByNestedKeyOf<StateMap, PropertyName>, dispatch];
};
