import classNames from "classnames";
import { html, useEffect, useState } from "haunted";
import { AA_LOGIN_URL } from "Services/constants";

export const useShadowDOM = false;
export const name = "aa-login-handler";

const loaderClassName = "dg-for-aa-login-loader";

export const Component = () => {
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const loader = window.newRtLoader(`.${loaderClassName}`);
		if (loader) {
			loader.startLoader();
		}

		const params = new URLSearchParams(window.location.search);
		const token = params.get("token");
		const errorDescription = params.get("error_description");

        if (!token) {
			setError(`No token`);
		}

        if (errorDescription){
            setError(errorDescription);
        }

		const formData = new FormData();
		formData.append("token", token);

		fetch(`${window.JetSmart.Settings.BookingUrl}/${AA_LOGIN_URL}`, {
			method: "POST",
			redirect: "follow",
			body: formData,
			credentials: "include",
		})
			.then((res) => {
				if (!res.ok) {
					setError(`Error logging in: ${res.statusText}`);
					return;
				}

				window.location.href = "/";
			})
			.catch((err) => {
				setError(`Error logging in: ${err?.message}`);
			})
			.finally(() => {
				if (loader) {
					loader.stopLoader();
					loader.destroy();
				}
			});
	}, []);

	const errorTemplate = error ? html`<div class="mt-4 text-red-500">${error}</div>` : "";

	return html`<div class=${classNames("min-h-svh", loaderClassName)}>${errorTemplate}</div>`;
};
