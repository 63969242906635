import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getAmericanTierDisplayNameTemplate, groupNumber } from "Services/common";
import { LINKS } from "Services/constants";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { useReduxState } from "Shared/redux/useReduxState";

export interface Props {
	culture: string;
	isHeaderSemiCollapsed: boolean;
	isAmericanDropdownOpen: boolean;
	setIsAmericanDropdownOpen: (isOpen: boolean) => void;
	closeCountryDropdown: () => void;
	handleLogout: () => void;
	handleCheckinModalOpen: () => void;
	handleAdminModalOpen: () => void;
}

export const useAmericanUserData = (props: Props) => {
	const [userInfo] = useReduxState("userInfo");

	// Events

	const handleAmericanDropdownClick = () => {
		props.setIsAmericanDropdownOpen(!props.isAmericanDropdownOpen);
		props.closeCountryDropdown();
	};

	const handleNavigateToAmericanAccount = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		window.open(`${LINKS.AMERICAN_USER_ACCOUNT_REDIRECT}?locale=${props.culture}`, "_blank");
	};

	// Templates

	const formattedAmericanTierNameTemplate = () => html`
		<div
			class=${classNames(
				"mb-1 mt-2 flex justify-center text-center font-semibold md:mb-0 md:text-[12px] md:group-hover:text-white",
				{
					"text-brand-secondary": !props.isAmericanDropdownOpen,
					"text-brand-secondary md:text-white": props.isAmericanDropdownOpen,
				}
			)}
		>
			${getAmericanTierDisplayNameTemplate(userInfo?.AmericanAirlines?.Tier)}
		</div>
	`;

	const americanTemplate = () => html`
		<div
			class=${classNames(
				"american-member-data-container min-w-full border-b-[1px] border-solid border-be-gray-9 md:min-w-0 md:border-0 md:flex md:flex-col md:justify-center md:h-full",
				{
					"dropdown-opened": props.isAmericanDropdownOpen,
				}
			)}
			tabindex="-1"
			@click=${handleAmericanDropdownClick}
		>
			<div
				class=${classNames(
					"american-displayed-name relative mb-1 text-center font-bold text-brand-tertiary md:group-hover:text-white",
					{
						"md:text-white": props.isAmericanDropdownOpen,
						"dropdown-opened": props.isAmericanDropdownOpen,
					}
				)}
			>
				${userInfo?.Name.FirstName} ${userInfo?.Name.LastName}
			</div>
			<div class="text-center tw-hidden md:block">${formattedAmericanTierNameTemplate()}</div>
		</div>
	`;

	const americanDropdownTemplateDesktop = () => html`
		<div
			class=${classNames(
				"absolute inset-x-1/2 z-20 max-h-0 w-fit -translate-x-1/2 overflow-hidden common-transition tw-hidden md:block",
				{
					"top-[92px]": !props.isHeaderSemiCollapsed,
					"top-[62px]": props.isHeaderSemiCollapsed,
					"max-h-[400px]": props.isAmericanDropdownOpen,
				}
			)}
		>
			<div class="flex w-full flex-col rounded-xl border-[1px] border-solid border-be-gray-9 bg-white p-4">
				<div class="mb-2 flex flex-row items-baseline justify-between">
					<span class="text-border-be-gray-2 whitespace-nowrap">${i18next.t("Available-miles")}: </span>
					<span class="ml-1 text-[17px]/6 font-bold text-brand-tertiary">
						${groupNumber(userInfo?.AmericanAirlines?.Miles, props.culture)}
					</span>
				</div>
				<div class="mb-4 flex flex-row items-baseline justify-between border-be-gray-9">
					<span class="flex">
						${unsafeHTML(
							i18next.t("AAdvantage {{-reg}}", {
								reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
							})
						)}:
					</span>
					<span class="ml-2 text-[17px]/6 font-bold">${userInfo?.AmericanAirlines?.AdvantageNumber}</span>
				</div>
				${americanUserButtonsTemplateDesktop()}
			</div>
		</div>
	`;

	const americanUserButtonsTemplateDesktop = () => html`
		<div class="mb-[2px] mt-0 flex flex-col items-center gap-2">
			<div
				class="dg-rounded-secondary-btn with-arrow longer"
				tabindex="-1"
				data-test-id=${TestIdDictionary.Menu.OpenAmericanAccountButton}
				@click=${(e: MouseEvent) => handleNavigateToAmericanAccount(e)}
			>
				<span>${i18next.t("My account")}</span>
			</div>
			<div
				class="dg-rounded-reverse-btn longer"
				tabindex="-1"
				data-test-id=${TestIdDictionary.Menu.LogOutButton}
				@click=${props.handleLogout}
			>
				<span>${i18next.t("layout-logout")}</span>
			</div>
		</div>
	`;

	const americanDropdownTemplateMobile = () => html`
		<div
			class=${classNames("z-20 block max-h-0 w-full overflow-hidden common-transition md:tw-hidden", {
				"max-h-[400px] border-b-[1px] border-solid border-be-gray-9": props.isAmericanDropdownOpen,
			})}
		>
			<div class="flex w-full flex-col bg-white md:p-4">
				${formattedAmericanTierNameTemplate()}
				<div class="flex flex-row items-baseline justify-center">
					<span class="text-sm/3 text-be-gray-2">${i18next.t("Available-miles")}: </span>
					<span class="ml-2 text-[16px]/6 font-bold text-brand-tertiary">
						${groupNumber(userInfo?.AmericanAirlines?.Miles, props.culture)}</span
					>
				</div>
				<div class="mb-2 flex flex-row items-baseline justify-center text-be-gray-2">
					<span class="flex text-sm/3">
						${unsafeHTML(
							i18next.t("AAdvantage {{-reg}}", {
								reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
							})
						)}:
					</span>
					<span class="ml-2 text-[16px]/6 font-bold">${userInfo?.AmericanAirlines?.AdvantageNumber}</span>
				</div>
			</div>
		</div>
	`;

	const americanListTemplateMobile = () => html`
		<div class="flex w-full flex-col md:tw-hidden">
			<div
				class="w-full border-b-[1px] border-solid border-be-gray-9 bg-white p-4 text-brand-secondary"
				tabindex="-1"
				data-test-id=${TestIdDictionary.Menu.LogOutButton}
				@click=${props.handleLogout}
			>
				<span>${i18next.t("layout-logout")}</span>
			</div>
			<div
				class="w-full border-b-[1px] border-solid border-be-gray-9 bg-white p-4 text-brand-secondary"
				tabindex="-1"
				data-test-id=${TestIdDictionary.Menu.OpenAmericanAccountButton}
				@click=${(e: MouseEvent) => handleNavigateToAmericanAccount(e)}
			>
				<span>${i18next.t("My account")}</span>
			</div>
			<div
				class="w-full border-b-[1px] border-solid border-be-gray-9 bg-white p-4 text-brand-secondary"
				tabindex="-1"
				data-test-id=${TestIdDictionary.Menu.CheckinOpener}
				@click=${props.handleCheckinModalOpen}
			>
				<span>${i18next.t("layout-check-in")}</span>
			</div>
			<div class="w-full bg-white p-4 text-brand-secondary" tabindex="-1" @click=${props.handleAdminModalOpen}>
				<span>${i18next.t("layout-manage-trip")}</span>
			</div>
		</div>
	`;

	const htmlTemplate = () =>
		userInfo?.AmericanAirlines?.IsMember
			? html` <div
					class=${classNames(
						"group flex cursor-pointer flex-col items-center justify-center px-3 py-2 md:py-0 hover:md:bg-header-secondary-color relative",
						{
							"bg-transparent": !props.isAmericanDropdownOpen,
							"md:bg-header-secondary-color": props.isAmericanDropdownOpen,
						}
					)}
			  >
					${americanTemplate()} ${americanDropdownTemplateDesktop()} ${americanDropdownTemplateMobile()}
					${americanListTemplateMobile()}
			  </div>`
			: "";

	return { htmlTemplate };
};
