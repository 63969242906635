import { useEffect, useState } from "Shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { VOUCHER_REGEX, PROMO_CODE_REGEX } from "Services/constants";
import { JetSmartEvent } from "Services/eventbus/JetSmartEvent";
import { tealiumLog } from "Services/TealiumHelpers";
import { getPromoCode } from "ComponentHelpers/PromoCodeHelper";
import { tealiumLogPreloadedPromoCode } from "ComponentHelpers/SearchboxTealiumHelpers";
import classNames from "classnames";
import { useReduxState } from "Shared/redux/useReduxState";

export interface Props {
	culture: string;
	dynamicSettings: DynamicSettings;
	isInFocus: boolean;
	isRedemptionCheckboxChecked: boolean;
}

export const usePromoCodeBox = (props: Props) => {
	const [userInfo] = useReduxState("userInfo");

	const [predefinedPromoCode, setPredefinedPromoCode] = useState<string>("");
	const [promoCode, setPromoCode] = useState<string>("");
	const [showPromoCodeFormatError, setShowPromoCodeFormatError] = useState<boolean>(false);
	const [showPromoCodeVoucherError, setShowPromoCodeVoucherError] = useState<boolean>(false);

	// Helpers

	const init = () => {
		if (!userInfo?.RoleCode) return;

		try {
			const pc = getPromoCode(userInfo, props.dynamicSettings, props.culture);

			setPromoCode(pc);
			setPredefinedPromoCode(pc);
			tealiumLogPreloadedPromoCode(pc);
		} catch (e) {
			throw new Error("Unable to parse user info cookie or set WA pc.");
		}
	};

	const validate = () => {
		setShowPromoCodeVoucherError(false);
		setShowPromoCodeFormatError(false);

		if (!promoCode || promoCode === predefinedPromoCode) return true;

		if (VOUCHER_REGEX.test(promoCode)) {
			setShowPromoCodeVoucherError(true);

			tealiumLog({
				eventName: "validation_error",
				eventParams: {
					page: "/",
					message: "[promo code appears to be a voucher]",
				},
			});

			return false;
		}

		if (!PROMO_CODE_REGEX.test(promoCode)) {
			setShowPromoCodeFormatError(true);

			tealiumLog({
				eventName: "validation_error",
				eventParams: {
					page: "/",
					message: "[invalid promo code format]",
				},
			});

			return false;
		}

		return true;
	};

	const displayedPromoCode = () => (predefinedPromoCode === promoCode ? getMaskedPromoCode() : promoCode);

	const getMaskedPromoCode = () => {
		if (!promoCode) return "";

		const lengthToMask =
			userInfo?.PeruCompra?.IsAdmin || userInfo?.PeruCompra?.IsMember ? promoCode.length : promoCode.length - 2;

		return `${[...Array(lengthToMask)].map(() => "*").join("")}${promoCode.substring(lengthToMask)}`;
	};

	// Event listeners

	const handleBlur = () => {
		window.eventBus.raiseEvent({
			name: JetSmartEvent.PromoCodeAdded,
			params: {
				id: "searchbox.promocode",
				event: "input",
				data: {
					code: promoCode,
				},
			},
		});
	};

	const handleInput = (e: KeyboardEvent) => {
		setShowPromoCodeVoucherError(false);
		setShowPromoCodeFormatError(false);

		const newPromoCode = (e.target as HTMLInputElement).value;

		setPromoCode(newPromoCode);
	};

	useEffect(() => {
		if (props.isRedemptionCheckboxChecked && userInfo?.AmericanAirlines?.IsMember) {
			setShowPromoCodeVoucherError(false);
			setShowPromoCodeFormatError(false);
			setPromoCode("");
		}
	}, [props.isRedemptionCheckboxChecked]);

	useEffect(init, [userInfo?.RoleCode]);

	// Templates

	const errorTemplate = () =>
		showPromoCodeFormatError || showPromoCodeVoucherError
			? html`
					<div class="row">
						<div class="col-xs-1">
							<div class="promo-code-error-container">
								<span class="field-error">
									${showPromoCodeVoucherError
										? i18next.t("promoCodeVoucherError")
										: i18next.t("promoCodeFormatError")}
								</span>
							</div>
						</div>
					</div>
			  `
			: "";

	const showPromoCode = () =>
		!userInfo?.ChileCompra?.IsMember &&
		(!props.isRedemptionCheckboxChecked || !userInfo?.AmericanAirlines.IsMember);

	const htmlTemplate = () =>
		showPromoCode()
			? html`
					<div class=${classNames("dg-promo-code", { "hidden-xs": !props.isInFocus })}>
						<input
							autocomplete="disabled"
							placeholder=${i18next.t("search-box-promo-code")}
							value=${displayedPromoCode()}
							.value=${displayedPromoCode()}
							?disabled=${userInfo?.PeruCompra?.IsAdmin || userInfo?.PeruCompra?.IsMember}
							@input=${handleInput}
							@blur=${handleBlur}
						/>
					</div>
					${errorTemplate()}
			  `
			: html``;

	return { predefinedPromoCode, promoCode, htmlTemplate, validate };
};
