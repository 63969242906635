import * as Immutable from "immutable";
import * as redux from "redux";
import { SimpleActionCreator, createAction, createReducer } from "redux-act";
import { combineReducers } from "redux-immutable";
import { PickLiteral, NestedKeyOf } from "./ReducerTypes";
import { MappedUserInfo } from "Shared/models/UserInfo";

interface AppStore extends redux.Store<Immutable.Map<any, any>> {}

export class ReduxContext {
	public static store: AppStore;

	public static dispatch(action: redux.Action) {
		ReduxContext.store.dispatch(action);
	}

	public static registerReducer(key: string, reducer: redux.Reducer<Immutable.Map<any, any>>) {
		ReduxContext.reducerRegistry[key] = reducer;
	}

	public static createStore() {
		const w: any = window as any;
		const rootReducer = combineReducers<Immutable.Map<any, any>, string>(ReduxContext.reducerRegistry);
		ReduxContext.store = redux.createStore(
			rootReducer,
			w.__REDUX_DEVTOOLS_EXTENSION__ && w.__REDUX_DEVTOOLS_EXTENSION__()
		);
	}

	private static readonly reducerRegistry: redux.ReducersMapObject = {};
}

export const REDUCER_NAME = "js-reducer";

export interface StateMap {
	userInfo: MappedUserInfo;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReducerAction = {
	action: SimpleActionCreator<StateMap["userInfo"]>;
	initialValue: StateMap["userInfo"];
	propertyName: PickLiteral<NestedKeyOf<StateMap>, "userInfo">;
};

const addPropertyByDotNotation = (object: any, pathName: string, value: any) => {
	const path = pathName.split(".");
	const last = path.pop();
	const lastObj = path.reduce((obj, key) => (obj[key] = obj[key] || {}), object);
	lastObj[last] = value;

	return object;
};

export const reducerActions: ReducerAction[] = [
	{
		action: createAction<MappedUserInfo>("userInfo"),
		initialValue: undefined,
		propertyName: "userInfo",
	},
];

const mappedActions = reducerActions.reduce(
	(actions, { propertyName, action }) => ({
		...actions,
		[action.toString()]: (state: Immutable.Map<any, any>, data: any) =>
			state.updateIn([...propertyName.split(".")], (_) => Immutable.fromJS(data)),
	}),
	{} as any
);

const mappedInitialState: StateMap = reducerActions.reduce(
	(state, { propertyName, initialValue }) => addPropertyByDotNotation(state, propertyName, initialValue),
	{} as StateMap
);

ReduxContext.registerReducer(
	REDUCER_NAME,
	createReducer<Immutable.Map<any, any>>(mappedActions, Immutable.fromJS(mappedInitialState))
);

ReduxContext.createStore();
